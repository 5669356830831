
import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Icon from "../components/icon"

import { useForm, ValidationError } from '@formspree/react'

function ContactPage({ location }) {

  const [state, handleSubmit] = useForm("mjvlennz") // Formspree form ID

  if (state.succeeded) {

    return (
      <Layout location={location}>
        <Seo title="Contact" />

        <div className="min-h-full px-4 py-80 bg-white sm:px-6 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-max">
            <main className="sm:flex">
              <div className="sm:ml-6 flex">
                <Icon id="email" size={80} />
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Thank you!</h1>
                  <p className="mt-1 text-base text-gray-500">Thank for your message! I will reply as soon as possible!</p>
                </div>
              </div>
            </main>
          </div>
        </div>
      </Layout>
    )

  } else {

    return (
      <Layout location={location}>
        <Seo title="Contact" />
        {/* TODO: pre-fill form based on query parameters */}
        <section className="pb-20 bg-gray-100 fullbleed mt-60 md:mt-40 lg:pb-34">
          <div className="container mt-48 md:grid md:grid-cols-2 md:pl-0">
            <div className="relative col-start-1 row-start-1 md:-top-19">
              <picture className="absolute -top-42 md:static">
                <source srcSet="/images/andrey-adamovich-01.jpg, /images/andrey-adamovich-01@2x.jpg 2x" media="(min-width: 720px)" />
                <img className="object-cover w-full h-auto md:absolute md:inset-0" src="/images/sm/andrey-adamovich-01.jpg" alt="Andrey Adamovich" srcSet="/images/sm/andrey-adamovich-01@2x.jpg 2x" width="343" height="375" loading="lazy" />
              </picture>
            </div>
            <div className="col-start-2 row-start-1 pt-64 md:mt-16 md:pl-12 md:pt-0 lg:mt-24 lg:pl-23.5">
              <h1 className="text-center">Contact Me</h1>
              <form className="mx-auto mt-9 max-w-111" onSubmit={handleSubmit}>
                <div className="grid gap-3.5">
                  <label className="block">
                    <span className="sr-only">Name</span>
                    <input id="name" name="name" type="text" className="block w-full px-6 py-4 border-transparent rounded text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Your Name" />
                    <ValidationError prefix="Name" field="name" errors={state.errors} />
                  </label>
                  <label className="block">
                    <span className="sr-only">Email</span>
                    <input id="email" name="email" type="email" className="block w-full px-6 py-4 border-transparent rounded text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Your Email" />
                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                  </label>
                  <label className="block">
                    <span className="sr-only">Your Question</span>
                    <textarea id="message" name="message" className="block w-full px-6 py-4 border-transparent rounded min-h-40 text-neutral-800 placeholder:text-neutral-400 focus:border-slate-300 focus:ring focus:ring-slate-200/50" placeholder="Your Question"></textarea>
                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                  </label>
                </div>
                <button className="w-full btn-primary mt-9" disabled={state.submitting}>Send Question</button>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )

  }
}

export default ContactPage
